<script>
  import page from "page";

  import { uuidv4 } from "./utility.js";
  import { afterUpdate } from "svelte";
  import { backgroundStore } from "./stores";

  import Scenario from "./Scenario.svelte";
  import Scenarios from "./Scenarios.svelte";

  let currentComponent = Scenarios;
  let currentScenario = "";
  let currentStep = 0;
  let fadeBackground = false;

  // Create a uuid if we don't have one yet.
  const id = sessionStorage.getItem("id");

  if (!id) {
    sessionStorage.setItem("id", uuidv4());
  }

  function backgroundLoaded() {
    fadeBackground = true;
  }

  afterUpdate(() => {
    if (currentComponent !== Scenarios) {
      // Hide homepage content.
      document.getElementById("homepage-content").classList.add("hidden");
    } else {
      // Show it!
      document.getElementById("homepage-content").classList.remove("hidden");
    }
  });

  // Routes
  page("/", () => {
    currentComponent = Scenarios;
  });

  page("/scenarios/:scenario/:step", ({ params }) => {
    currentScenario = params.scenario;
    currentComponent = Scenario;
    currentStep = params.step;
  });

  page();
</script>

{#if $backgroundStore}
  <picture>
    <source srcset={`${$backgroundStore}.webp`} type="image/webp" />
    <img
      src={`${$backgroundStore}.jpg`}
      alt=""
      role="presentation"
      on:load={backgroundLoaded}
      class={`fixed left-0 top-0 h-full z-0 ${fadeBackground ? 'animated fade-in-right' : ''}`} />
  </picture>
{/if}

<div class="relative z-10">
  <svelte:component
    this={currentComponent}
    scenario={currentScenario}
    step={currentStep} />
</div>
