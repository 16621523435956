<script>
  import { onMount } from "svelte";
  import { fly } from "svelte/transition";
  import { backgroundStore } from "./stores";

  let scenarios = [];

  onMount(() => {
    backgroundStore.set();

    setTimeout(() => {
      backgroundStore.set("/img/homepage");
    }, 0);

    fetch("/scenarios/index.json")
      .then(response => response.json())
      .then(body => {
        scenarios = body.scenarios;
      });

    document.title = "Turning Points | MRescue";
  });
</script>

<style>
  a,
  .scenario {
    transition: all 0.25s ease;
  }

  a:hover .scenario,
  a:focus .scenario {
    outline: 2px solid #e3e3e3;
    border-radius: 0;
    box-shadow: none;
    transition: box-shadow 0.25s ease;
  }

  a:hover .button,
  a:focus .button {
    box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.19),
      0 1px 6px 0 rgba(0, 0, 0, 0.26);
    transition: box-shadow 0.25s ease;
  }
</style>

{#if scenarios.length > 0}
  <div class="flex justify-end" in:fly={{ y: 16, duration: 500, delay: 250 }}>
    <div class="md:flex flex-wrap justify-between md:-m-4 xl:max-w-4xl">
      {#each scenarios as scenario}
        <a
          class="md:w-1/2 md:p-4 block mb-8 lg:mb-0"
          href="/scenarios/{scenario.slug}/1">
          <div
            class="scenario shadow rounded-sm bg-bright-white-95 h-full flex
            relative flex-col overflow-hidden">
            {#if sessionStorage.getItem(scenario.slug)}
              <div
                class="w-24 h-24 bg-blue-chill absolute -right-3 -top-3
                rotate-135" />
              <img
                class="absolute right-05 top-075"
                src="/img/check.svg"
                alt="Scenario completed" />
            {/if}
            <h2 class="text-2xl px-8 py-6 font-medium text-darkness">
              {scenario.title}
            </h2>
            <picture>
              <source type="image/webp" srcset={scenario.image.replace('.jpg', '.webp')} />
              <source type="image/jpeg" srcset={scenario.image} />
              <img
                class="w-full h-48 object-cover"
                src={scenario.image}
                alt={scenario.image_alt} />
            </picture>
            <div class="px-8 py-6 flex flex-col flex-grow">
              <p class="text-mid-grey mb-4">{scenario.summary}</p>
              <div
                class="button bg-chambray text-athens-grey block rounded-sm
                uppercase text-center py-2 font-bold mt-auto"
                href="/scenarios/{scenario.slug}">
                Begin Scenario »
              </div>
            </div>
          </div>
        </a>
      {/each}
    </div>
  </div>
{/if}
