<script>
  import tippy from "tippy.js";

  import { onMount } from "svelte";
  import { scenariosStore } from "./stores.js";
  import { fly } from "svelte/transition";

  export let scenarioData;

  let totalSteps = scenarioData.steps.length;
  let choices = [];
  let answerChosen = [];
  let currentStep = 0;

  // Find choices that were not made for each step.
  if ($scenariosStore.steps.length) {
    scenarioData.steps.forEach((step, i) => {
      choices.push(
        step.choices.filter(
          choice => choice.title !== $scenariosStore.steps[i].title
        )
      );
      answerChosen.push(
        ...step.choices.filter(
          choice => choice.title === $scenariosStore.steps[i].title
        )
      );
    });
  }

  onMount(() => {
    tippy(".tippy");
  });

  function keyPress(event) {
    // Handle moving forward.
    if (event.key === "ArrowRight" && currentStep < totalSteps * 4 + 1) {
      next();
    }

    // Handle moving backwards.
    if (event.key === "ArrowLeft" && currentStep) {
      previous();
    }
  }

  function previous() {
    currentStep--;
    document.getElementById(`step${currentStep}`).focus();
  }

  function next() {
    currentStep++;
    document.getElementById(`step${currentStep}`).focus();
  }
</script>

<style>
  .tippy:hover,
  .tippy:focus,
  button:hover circle,
  button:focus circle {
    fill: #8a566f;
  }
</style>

<svelte:window on:keydown={keyPress} />

{#if choices.length}
  <svg
    width="270px"
    class="flex-shrink-0 mx-auto"
    height={totalSteps * 250}
    version="1.1"
    xmlns="http://www.w3.org/2000/svg">
    {#each scenarioData.steps as step, stepIndex}
      {#each choices[stepIndex] as choice, choiceIndex}
        <line
          x1="137"
          y1={stepIndex * 209 + 40}
          x2={choiceIndex * 215 + 30}
          y2={stepIndex * 209 + 145}
          stroke="#fafbfc"
          stroke-width="3"
          stroke-dasharray="8" />

        <line
          x1={choiceIndex * 215 + 30}
          y1={stepIndex * 209 + 145}
          x2="137"
          y2={(stepIndex + 1) * 209 + 40}
          stroke="#fafbfc"
          stroke-width="3"
          stroke-dasharray="8" />
      {/each}

      <line
        x1="137"
        y1={stepIndex * 209 + 40}
        x2="137"
        y2={stepIndex * 209 + 245}
        stroke="#fafbfc"
        stroke-width="8" />

      <circle
        class="step tippy"
        id={`step${stepIndex * 4 + 1}`}
        cx="137"
        cy={stepIndex * 209 + 40}
        r="32"
        stroke="#fafbfc"
        fill="#33417f"
        stroke-width="8"
        tabindex="0"
        data-tippy-content="<strong>Step {stepIndex + 1}</strong> <br><br> {step.description}" />

      <circle
        class="chosen-answer tippy"
        id={`step${stepIndex * 4 + 2}`}
        cx="137"
        cy={stepIndex * 209 + 145}
        r="20"
        stroke="#fafbfc"
        stroke-width="8"
        fill="#33417f"
        tabindex="0"
        data-tippy-content="<strong>{answerChosen[stepIndex].title}</strong> <br><br> {answerChosen[stepIndex].result}" />
      {#each choices[stepIndex] as choice, choiceIndex}
        <circle
          class="choice tippy"
          id={`step${stepIndex * 4 + 3 + choiceIndex}`}
          cx={choiceIndex * 215 + 30}
          cy={stepIndex * 209 + 145}
          r="20"
          stroke="#fafbfc"
          stroke-width="8"
          fill="#148c9a"
          tabindex="0"
          data-tippy-content="<strong>{choice.title}</strong> <br><br> {choice.result}" />
      {/each}
    {/each}

    <circle
      class="circle tippy"
      id={`step${totalSteps * 4 + 1}`}
      cx="137"
      cy={totalSteps * 209 + 36}
      r="32"
      stroke="#fafbfc"
      fill="#33417f"
      stroke-width="8"
      tabindex="0"
      data-tippy-content="<strong>Conclusion</strong>" />
  </svg>

  {#if currentStep}
    <button
      class="absolute left-1 bottom-1 rotate-180"
      on:click={previous}
      on:keyup={keyPress}
      transition:fly={{ x: 100, duration: 250 }}>
      <svg
        class="tippy"
        xmlns="http://www.w3.org/2000/svg"
        width="44"
        height="44"
        viewBox="0 0 44 44">
        <g
          fill="none"
          fill-rule="evenodd"
          stroke="#F4F8F9"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="4"
          transform="matrix(-1 0 0 1 42 2)">
          <circle cx="20" cy="20" r="20" />
          <path d="M20 12l-8 8 8 8M28 20H12" />
        </g>
      </svg>
    </button>
  {/if}

  {#if currentStep < totalSteps * 4 + 1}
    <button
      class="absolute right-1 bottom-1"
      on:click={next}
      on:keyup={keyPress}
      transition:fly={{ x: 100, duration: 250 }}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="44"
        height="44"
        viewBox="0 0 44 44">
        <g
          fill="none"
          fill-rule="evenodd"
          stroke="#F4F8F9"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="4"
          transform="matrix(-1 0 0 1 42 2)">
          <circle cx="20" cy="20" r="20" />
          <path d="M20 12l-8 8 8 8M28 20H12" />
        </g>
      </svg>
    </button>
  {/if}
{/if}
