<script>
  export let href;
  export let color;
</script>

<a
  {href}
  class="{color === 'chambray' ? 'bg-chambray text-athens-grey hover:bg-old-greg focus:bg-old-greg hover:text-darkness focus:text-darkness' : ''}
  {color === 'oldGreg' ? 'bg-old-greg text-darkness hover:bg-chambray hover:text-athens-grey focus:bg-chambray focus:text-athens-grey' : ''}
  inline-block rounded-sm uppercase text-center py-2 px-8 font-bold
  cursor-pointer">
  <slot />
  »
</a>
