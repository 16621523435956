import { writable } from "svelte/store";

// Grab questionnaire data from session storage if it exists.
const questionnaireData = sessionStorage.getItem("questionnaire")
  ? sessionStorage.getItem("questionnaire")
  : false;

export const questionnaireStore = writable(questionnaireData);

// Scenarios store.
const scenariosData = sessionStorage.getItem("scenarios")
  ? sessionStorage.getItem("scenarios")
  : {steps: []};

export const scenariosStore = writable(scenariosData);

// Page background store.
export const backgroundStore = writable();
