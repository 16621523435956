<script>
  import page from "page";

  import { onMount, afterUpdate } from "svelte";
  import { fly } from "svelte/transition";
  import { scenariosStore, backgroundStore } from "./stores.js";
  import { differenceInSeconds } from "date-fns";

  import PageTitle from "./PageTitle.svelte";
  import FormSubmit from "./FormSubmit.svelte";

  export let currentStep;
  export let scenarioData;
  export let step;
  export let scenario;
  export let result;
  export let loading;

  let currentChoice;
  let startTime;
  let endTime;

  $: {
    document.title = `Step ${step} | ${scenarioData.title} | MRescue`;
  }

  onMount(() => {
    // Check to see if we have step 1 data, if not, send em back.
    if (!$scenariosStore.steps.length) {
      page(`/scenarios/${scenario}/1`);
    }

    // Start a timer.
    startTime = new Date();

    backgroundStore.set();

    setTimeout(() => {
      // Set background.
      backgroundStore.set(`/img/step${step}`);
    }, 0);
  });

  function handleSubmit() {
    const choice = scenarioData["steps"][step - 1]["choices"][currentChoice];

    // Set end time.
    endTime = new Date();

    // Add minutes on the step to the choice.
    choice.time = differenceInSeconds(endTime, startTime);

    // Save data to store.
    const scenariosStoreData = $scenariosStore;

    // Either overwrite the choice or add a new entry.
    if (scenariosStoreData.steps[[step - 1]]) {
      scenariosStoreData.steps[[step - 1]] = choice;
    } else {
      scenariosStoreData.steps.push(choice);
    }

    // Check to see if the user has a touch device.
    scenariosStoreData["device"] = navigator.maxTouchPoints
      ? "touch"
      : "standard";

    scenariosStore.set(scenariosStoreData);

    // Set the result.
    result = choice.result;

    // Start new timer.
    startTime = new Date();

    // Change the background image to the next step.
    backgroundStore.set();

    setTimeout(() => {
      backgroundStore.set(`/img/step${step}`);
    }, 0);

    // Trigger loading to allow a fly fade transition.
    loading = true;

    setTimeout(() => {
      loading = false;
    }, 0);

    // Redirect user to next page or the conclusion if we're all done.
    step < scenarioData.steps.length
      ? page(`/scenarios/${scenario}/${parseInt(step) + 1}`)
      : page(`/scenarios/${scenario}/conclusion`);
  }
</script>

<PageTitle heading={scenarioData.title} />

{#if parseInt(step) === 1}
  <p class="mb-8 prose">{@html scenarioData.description}</p>
{/if}

{#if result}
  <p class="mb-8 prose">{@html result}</p>
{/if}

<p class="mb-8 prose">
  {@html currentStep.description}
</p>

<form on:submit|preventDefault={handleSubmit}>
  <fieldset class="mb-8">
    <legend class="mb-4 font-semibold">{currentStep.choice_label}</legend>
    {#each currentStep.choices as choice, index}
      <div class="flex items-center mb-1 font-medium">
        <input
          type="radio"
          id={`choice-${index}`}
          name="role"
          bind:group={currentChoice}
          required
          value={index} />
        <label for={`choice-${index}`} class="ml-3">{choice.title}</label>
      </div>
    {/each}
  </fieldset>
  <FormSubmit value="Submit your responses" />
</form>
