<script>
  import { onMount } from "svelte";
  import { fade } from "svelte/transition";
  import { questionnaireStore, backgroundStore } from "./stores";
  import { currentDate } from "./utility.js";

  import PageTitle from "./PageTitle.svelte";
  import FormSubmit from "./FormSubmit.svelte";

  let loaded = false;
  let id = sessionStorage.getItem("id");
  let date = currentDate();

  let questionnaire = {
    role: "",
    gender: "",
    yearsWorked: "",
    yearsRole: "",
    completedBefore: ""
  };

  let title = "";
  let content = "";

  onMount(() => {
    // Fetch our dynamic content.
    fetch("/questionnaire/index.json")
      .then(response => response.json())
      .then(body => {
        title = body.title;
        content = body.content;

        document.title = `${title} | MRescue`;
      });

    backgroundStore.set();

    setTimeout(() => {
      // Set our background image.
      backgroundStore.set("/img/questionnaire");
    }, 0);

    // Trigger the fade in.
    loaded = true;
  });

  function handleSubmit() {
    // Set state with answers to use later.
    questionnaireStore.set(questionnaire);

    // Add to session storage.
    sessionStorage.setItem("questionnaire", JSON.stringify(questionnaire));

    // Add data to Google Sheet.
    fetch("https://us-central1-mrescue-prod.cloudfunctions.net/addRow", {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        range: "Questionnaire!A1",
        values: [[date, id, ...Object.values(questionnaire)]]
      })
    });
  }
</script>

{#if loaded}
  <PageTitle heading={title} />
  <div class="font-semibold text-mid-grey mb-6">{@html content}</div>

  <form on:submit|preventDefault={handleSubmit} class="text-mid-grey">

    <fieldset class="mb-8">
      <legend class="font-semibold mb-4">1. What is your role?</legend>
      <div class="font-medium flex items-center mb-1">
        <input
          type="radio"
          id="role-house-officer"
          name="role"
          bind:group={questionnaire.role}
          required
          value="House Officer" />
        <label for="role-house-officer" class="ml-3">House Officer</label>
      </div>
      <div class="font-medium flex items-center mb-1">
        <input
          type="radio"
          id="role-provider"
          name="role"
          required
          bind:group={questionnaire.role}
          value="Advance Practice Provider" />
        <label for="role-provider" class="ml-3">
          Advance Practice Provider
        </label>
      </div>
      <div class="font-medium flex items-center mb-1">
        <input
          type="radio"
          id="role-other"
          name="role"
          required
          bind:group={questionnaire.role}
          value="Other" />
        <label for="role-other" class="ml-3">Other</label>
      </div>
    </fieldset>

    <fieldset class="mb-8">
      <legend class="font-semibold mb-4">2. What is your gender?</legend>
      <div class="font-medium flex items-center mb-1">
        <input
          type="radio"
          id="gender-male"
          name="gender"
          value="Male"
          required
          bind:group={questionnaire.gender} />
        <label for="gender-male" class="ml-3">Male</label>
      </div>
      <div class="font-medium flex items-center mb-1">
        <input
          type="radio"
          id="gender-female"
          name="gender"
          value="Female"
          required
          bind:group={questionnaire.gender} />
        <label for="gender-female" class="ml-3">Female</label>
      </div>
      <div class="font-medium flex items-center mb-1">
        <input
          type="radio"
          id="gender-no-answer"
          name="gender"
          bind:group={questionnaire.gender}
          required
          value="Prefer not to answer" />
        <label for="gender-no-answer" class="ml-3">Prefer not to answer</label>
      </div>
    </fieldset>

    <div class="mb-8">
      <label for="years-worked" class="block font-semibold mb-4">
        3. How many years have you worked in health care?
      </label>
      <div class="select-wrapper">
        <select
          class="block"
          id="years-worked"
          bind:value={questionnaire.yearsWorked}
          required>
          <option value="">Select number of years</option>
          <option value="0-5 years">0-5 years</option>
          <option value="5-10 years">5-10 years</option>
          <option value="10-15 years">10-15 years</option>
          <option value="15+ years">15+ years</option>
        </select>
      </div>
    </div>

    <div class="mb-8">
      <label for="years-role" class="block font-semibold mb-4">
        4. How many years have you been in your current role?
      </label>
      <div class="select-wrapper">
        <select
          class="block"
          id="years-role"
          bind:value={questionnaire.yearsRole}
          required>
          <option value="">Select number of years</option>
          <option value="0-5 years">0-5 years</option>
          <option value="5-10 years">5-10 years</option>
          <option value="10-15 years">10-15 years</option>
          <option value="15+ years">15+ years</option>
        </select>
      </div>
    </div>

    <fieldset class="mb-8">
      <legend class="font-semibold mb-4">
        5. Have you completed this scenario before?
      </legend>
      <div class="font-medium flex items-center mb-1">
        <input
          type="radio"
          id="completed-yes"
          name="completed"
          value="Yes"
          required
          bind:group={questionnaire.completedBefore} />
        <label for="completed-yes" class="ml-3">Yes</label>
      </div>
      <div class="font-medium flex items-center mb-1">
        <input
          type="radio"
          id="completed-no"
          name="completed"
          value="No"
          required
          bind:group={questionnaire.completedBefore} />
        <label for="completed-no" class="ml-3">No</label>
      </div>
    </fieldset>

    <FormSubmit value="Submit your responses" />
  </form>
{/if}
