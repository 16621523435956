<script>
  import PageTitle from "./PageTitle.svelte";
  import PathExplorer from "./PathExplorer.svelte";

  import { scenariosStore } from "./stores.js";

  export let scenarioData;
</script>

<div class="flex flex-col h-full">
  <div class="px-8 pt-8 pb-2 prose border-b">
    <div class="mb-3 font-bold uppercase">Debiasing strategies</div>

    <div class="w-24 h-2 mb-4 bg-blue-chill" />

    <div>
      {#each $scenariosStore.steps as step}
        <p>{@html step.debiasing_strategy}</p>
      {/each}
    </div>
  </div>

  <header class="p-8 text-mine-shaft">
    <div class="mb-4 font-bold uppercase">Scenario Steps</div>
    <PageTitle heading={scenarioData.title} />
    <p class="text-xs">
      Follow the <strong>solid line</strong> path with cursor to review your
      decisions and outcomes. Follow the <strong>dotted lines</strong> with cursor
      to explore alternative decisions. Use the arrow at bottom right to step through
      the scenario.
    </p>
  </header>

  <div
    class="relative flex items-center flex-grow p-5 overflow-auto font-medium bg-old-greg-93 text-bright-white"
  >
    <PathExplorer {scenarioData} />
  </div>
</div>
