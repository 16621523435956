<script>
  import page from "page";

  import { onMount } from "svelte";
  import { fly, fade } from "svelte/transition";
  import { questionnaireStore, scenariosStore } from "./stores.js";

  import Questionnaire from "./Questionnaire.svelte";
  import PageTitle from "./PageTitle.svelte";
  import FormSubmit from "./FormSubmit.svelte";
  import Step from "./Step.svelte";
  import Conclusion from "./Conclusion.svelte";
  import Progress from "./Progress.svelte";
  import Button from "./Button.svelte";
  import Recap from "./Recap.svelte";

  export let scenario;
  export let step;
  
  let scenarioData = {};
  let currentStep = {};
  let completedPercentage = "";
  let result = "";
  let loading = true;

  $: if (scenarioData.steps) {
    if (step === "conclusion") {
      completedPercentage = "100%";
    } else {
      currentStep = scenarioData.steps[step - 1];

      // Set our completion percentage for progress bar.
      completedPercentage = `${
        (parseInt(step) / (parseInt(scenarioData.steps.length) + 1)) * 100
      }%`;
    }
  }
  
  onMount(() => {
    // Clear out scenarios store.
    scenariosStore.set({ steps: [] });

    fetch(`/scenarios/${scenario}/index.json`)
      .then((response) => response.json())
      .then((body) => (scenarioData = body));

    loading = false;
  });
</script>

{#if scenarioData.title && !loading}
  <div
    class="flex flex-col items-start justify-end lg:flex-row"
    in:fly={{ y: 16, duration: 500, delay: 250 }}
  >
    <div class="self-stretch w-full max-w-4xl p-8 shadow bg-bright-white-90">
      {#if !$questionnaireStore}
        <Questionnaire />
      {:else if step === "conclusion"}
        <Conclusion {scenarioData} {currentStep} {step} {scenario} {result} />
      {:else}
        <Step
          bind:result
          bind:loading
          {scenarioData}
          {currentStep}
          {step}
          {scenario}
        />
      {/if}
      {#if $questionnaireStore}
        <Progress {completedPercentage} {scenarioData} {step} />
      {/if}
      {#if step === "conclusion"}
        <div class="mt-8">
          <div class="mb-8">
            <Button color="chambray" href="/">Try another scenario</Button>
          </div>
          <div class="font-semibold text-darkness">Finished?</div>
          <Button color="oldGreg" href="/survey">Take our brief survey</Button>
        </div>
      {/if}
    </div>
    {#if step === "conclusion"}
      <div
        class="mt-8 shadow lg:max-w-xs lg:mt-0 lg:ml-8 bg-bright-white-90 lg:bg-transparent"
      >
        <Recap {scenarioData} />
      </div>
    {/if}
  </div>
{/if}
