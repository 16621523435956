<script>
  import page from "page";

  import { onMount } from "svelte";
  import { scenariosStore, backgroundStore } from "./stores.js";
  import { currentDate } from "./utility.js";
  import { fade } from "svelte/transition";

  import PageTitle from "./PageTitle.svelte";
  import IntersectionObserver from "./IntersectionObserver.svelte";

  export let scenarioData;
  export let currentStep;
  export let step;
  export let scenario;
  export let result;

  let id = sessionStorage.getItem("id");
  let date = currentDate();

  $: {
    document.title = `Conclusion | ${scenarioData.title} | MRescue`;
  }

  onMount(() => {
    // Send user back to the beginning if there is no scenario data.
    if (!$scenariosStore.steps.length) {
      page(`/scenarios/${scenario}/1`);
    } else {
      let time = 0;

      backgroundStore.set();

      setTimeout(() => {
        // Update page background.
        backgroundStore.set("/img/conclusion");
      }, 0);

      // Grab our step titles.
      const stepData = [];

      $scenariosStore.steps.forEach((step) => {
        // Increase time to keep track of total.
        time += step.time;

        // Send back the title and time only.
        stepData.push(step.title);
        stepData.push(step.time);
      });

      const device = $scenariosStore.device;

      // Send our data to a spreadsheet.
      fetch("https://us-central1-mrescue-prod.cloudfunctions.net/addRow", {
        method: "POST",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          range: `${scenarioData.sheet}!A1`,
          values: [[date, id, device, ...stepData, time]],
        }),
      });

      // Set this scenario as complete.
      sessionStorage.setItem(scenarioData.slug, true);
    }
  });
</script>

<PageTitle heading={scenarioData.title} />

<p class="mb-8 prose">{@html result}</p>

<div class="prose">
  {@html scenarioData.conclusion}

  <div class="mb-8">
    {#if scenarioData.conclusionSections.length > 0}
      {#each scenarioData.conclusionSections as section}
        <div>
          <h2>
            {section.heading}
          </h2>
          <p>{@html section.content}</p>
        </div>
      {/each}
    {/if}
  </div>

  {#if scenarioData.moreInformationLink}
    <a
      class="font-bold underline text-blumine"
      href={scenarioData.moreInformationLink}>
      {scenarioData.moreInformationText} »
    </a>
  {/if}
</div>
