<script>
  export let completedPercentage;
  export let scenarioData;
  export let step;
</script>

<style>
  .progress {
    background-image: linear-gradient(to left, #ebebeb, var(--blue-chill));
    transition: width 1s ease-out;
  }
  .progress.complete {
    background-color: var(--blue-chill);
    background-image: none;
  }
</style>

<div class="mt-8">
  <div class="text-lg uppercase font-bold mb-2">
    {#if completedPercentage === '100%'}
      Complete!
    {:else}Step {step} of {scenarioData.steps.length}{/if}
  </div>
  {#if completedPercentage}
    <div class="h-4 w-full bg-pale-grey rounded-lg relative">
      <div
        class="progress absolute left-0 rounded-lg h-4 w-0 {completedPercentage === '100%' ? 'complete' : ''}"
        style="width: {completedPercentage}" />
    </div>
  {/if}
</div>
